<template>
  <div id="download">
    <div style="background-color:white;">
      <nav class="navbar navbar-expand-lg navbar-light navbar-floating">
        <div class="container">
          <a class="navbar-brand" href="#">
            <img :src="datas.logoUrl" alt="" width="40" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>

      <div
        class="page-hero-section bg-image hero-home-1"
        :style="{
          backgroundImage: `url(${datas.backgroundImageUrl})`,
        }"
      >
        <div class="hero-caption pt-5">
          <div class="container h-100">
            <div class="row align-items-center h-100">
              <div class="col-lg-6 wow fadeInUp">
                <h1 class="mb-4">{{ datas.title }}</h1>
                <pre class="mb-4"
                  >{{ datas.desc }}
              </pre
                >
                <a
                  :href="downloadAddress.androidAddress || 'javascript:;'"
                  :target="downloadAddress.androidAddress ? '_blank' : ''"
                  class="btn btn-primary rounded-pill"
                  >Android 下载</a
                >
                <a
                  :href="downloadAddress.IOSAddress || 'javascript:;'"
                  :target="downloadAddress.IOSAddress ? '_blank' : ''"
                  class="btn btn-dark rounded-pill ml-1"
                  >iOS 下载</a
                >
              </div>
              <div class="col-lg-6 d-none d-lg-block wow zoomIn">
                <div class="img-place mobile-preview shadow floating-animate">
                  <img :src="datas.mobileSnapShotSrc" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Clients -->
      <div class="page-section mt-5">
        <div class="container">
          <div class="row">
            <template v-for="brand of datas.brands">
              <div class="col-sm-6 col-lg-3 py-3 wow zoomIn" :key="brand.src">
                <div class="img-place client-img">
                  <img :src="brand.src" alt="" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- End clients -->

      <div class="page-footer-section bg-dark fg-white">
        <div class="container">
          <div class="row mb-5 py-3">
            <div class="col-sm-12 col-lg-4 py-3">
              <h5 class="mb-3 text-white">关于我们</h5>
              <ul class="menu-link">
                <li v-for="about of datas.abouts" :key="about.text">
                  <pre class="mb-4 text-white"
                    >{{ about.text }}
                </pre
                  >
                  <!-- <a :href="about.href || 'javascript:;'">{{ about.text }}</a> -->
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-4 py-3">
              <h5 class="mb-3 text-white">Contact</h5>
              <ul class="menu-link">
                <li v-for="contact of datas.contacts" :key="contact.text">
                  <a :href="contact.href || 'javascript:;'">{{ contact.text }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/test/img/favicon.png'
import bgImg from '@/assets/test/img/bg_hero_1.svg'
import mobile from '@/assets/test/img/app_preview_1.png'
import brand1 from '@/assets/test/img/clients/alter_sport.png'
import brand2 from '@/assets/test/img/clients/cleaning_service.png'
import brand3 from '@/assets/test/img/clients/creative_photo.png'
import brand4 from '@/assets/test/img/clients/global_tv.png'
import WOW from '@/assets/test/vendor/wow/wow.min.js'
import qs from 'querystring'
import { getDownloadPage, getAppUpdate } from '@/api/app/index'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      datas: {},
      downloadAddress: {
        IOSAddress: '',
        androidAddress: '',
      },
    }
  },
  created() {
    if (this.isEdit) {
      this.datas = this.value
    } else {
      const { id } = qs.parse(window.location.search ? window.location.search.substring(1) : '')

      getDownloadPage(id).then((res) => {
        this.datas = JSON.parse(res.data.datas || '{}')
        this.downloadAddress = res.data.downloadAddress
      })
    }
  },

  mounted() {
    const wow = new WOW()

    wow.init()
  },
}
</script>

<style>
/* @import '~@/assets/test/css/bootstrap.css'; */
@import '~@/assets/test/vendor/animate/animate.css';
@import '~@/assets/test/css/mobster.css';
</style>
