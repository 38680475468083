import axios from 'axios'
import { Message } from '@/plugins/alertNotifys'
import store from '../store'
import { getToken, get } from '@/utils/auth'


// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api的base_url
    timeout: 10000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
    if (store.getters.token) {
        // let test = config.data;
        // if (test) {
        //     config.data['access_token'] = getToken()
        // }

        if (get('storeId') && 'undefined' != get('storeId')) {
            config.headers['storeid'] = get('storeId')
        }
        if (config.headers['Content-Type']) {
            config.headers['Content-Type'] = config.headers['Content-Type']
        }
        config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
        config.headers['client_id'] = 'app';
        config.headers['client_secret'] = 'app';
    }
    return config
}, error => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        // console.log('response: ', response)

        // 如果文件内容包含ms-excel,返回整个response，生成excel下载
        if (response.headers['content-type'].includes('ms-excel') || response.headers['content-type'].includes('text/html')) {
            return response
        }
        /**
         * code为非200是抛错 可结合自己业务进行修改
         */
        const res = response.data
        if (res.code !== 200 && !res.access_token) {
            Message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
            })

            // 401:未登录;
            if (res.code === 401 || res.code === 403) {
                // MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
                //     confirmButtonText: '重新登录',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {

                // })
                // console.log('res', response)
                store.dispatch('FedLogOut').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            }
            return Promise.reject(response)
        } else {
            return response.data
        }
    },
    error => {
        console.log(error, error.response);
        Message({
            message: error.response ? error.response.msg : error.message,
            type: 'error',
            duration: 3 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
