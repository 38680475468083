
const chat = {
  namespaced: true,
  state: {
    ding: false,
  },
  mutations: {
    TOGGLE_DING: (state, payload) => {
      state.ding = payload
    },
  },

}

export default chat
