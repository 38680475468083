// import Cookies from 'js-cookie'

const themeType = {
  namespaced: true,
  state: {
    themeType: 'default',
    themeMenuBgColor: {
      default: '#ffffff',//'#ecf5ff',
      red: '#ffffff',//'#ef6666',
      teal: '#ffffff',//'#1ea5a5'
    },
    defaultActive: {}
  },
  mutations: {
    SET_THEME_TYPE: (state, themeType) => {
      state.themeType = themeType
    },
    SET_defaultActiveIndex: (state, payload) => {
      // console.log('SET_defaultActive-----payload:',payload)
      state.defaultActive = payload
    }
  },
  actions: {
    SetThemeType({ commit }, themeType) {
      commit('SET_THEME_TYPE', themeType)
    }
  }
}

export default themeType
