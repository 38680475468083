import request from '@/utils/request'

export function login(username, password) {
  // console.log('login----', username, password)
  return request({
    url: '/shop/noauth/login',
    method: 'post',
    data: {
      username,
      password,
    },
  })
}

export function getInfo() {
  return request({
    url: '/shop/sys/user/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/shop/sys/sysUser/logout',
    method: 'post',
  })
}

export function sysMenuNav() {
  return request({
    url: '/shop/sys/menu/nav',
    method: 'get',
  })
}

export function sysMenuList() {
  return request({
    url: '/shop/sys/menu/list',
    method: 'get',
  })
}
