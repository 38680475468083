import request from '@/utils/request'

/**
 * 获取下载页配置
 * @returns
 */
export function getPage() {
  return request({
    url: `/shop/shop/config/getDownloadPage`,
    method: 'get',
  })
}

/**
 * 保存下载页配置
 * @param {Object} data 页面配置
 * @returns
 */
export function setPage(data) {
  return request({
    url: `/shop/shop/config/updateDownloadPage`,
    method: 'post',
    data
  })
}

/**
 * 匿名获取下载页配置
 * @param {Number} storeId 商户id
 * @returns
 */
export function getDownloadPage(storeId) {
  return request({
    url: `/shop/noauth/getDownloadPage/${storeId}`,
    method: 'get',
  })
}

/**
 * 获取App的下载地址
 * @param {Number} storeId 商户id
 * @param {String} type App类型，AND和iOS
 * @returns
 */
export function getAppUpdate(storeId, type) {
  return request({
    url: `/app/api/home/appUpdate/${type}`,
    headers: {
      storeId,
    },
    method: 'get',
  })
}
