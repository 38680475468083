import { login, logout, getInfo } from '@/api/login/login'
import { getToken, setToken, removeToken, set } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    storeName: '',
    menus: [],
    roles: [],
    naviMenus: [],
    storeId: '',
    wechatNickname: '',
    wechatOpenId: '',
    expired: false,
  },

  mutations: {
    SET_SAVE: (state, payload) => {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_WECHAT_NAME: (state, wechatNickname) => {
      state.wechatNickname = wechatNickname
      sessionStorage.wechatNickname = wechatNickname
    },
    SET_WECHAT_OPENID: (state, openId) => {
      state.wechatOpenId = openId
      sessionStorage.wechatOpenId = openId
    },
    SET_NAME: (state, name) => {
      state.name = name
      sessionStorage.name = name
    },
    SET_AVATAR: (state, avatar) => {
      if (avatar) {
        state.avatar = avatar
      }
    },
    SET_STORENAME: (state, storeName) => {
      state.storeName = storeName
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MENUS: (state, menus) => {
      // console.log('SET_MENUS====', menus)
      state.menus = menus
      sessionStorage.menus = JSON.stringify(menus)
    },
    SET_NAVI_MENUS: (state, naviMenus) => {
      // console.log('SET_NAVI_MENUS====', naviMenus)
      state.naviMenus = naviMenus
      sessionStorage.naviMenus = JSON.stringify(naviMenus)
    },

    // 设置过期状态
    SET_EXPIRED_STATUS: (state, value) => {
      state.expired = value
    },
  },

  actions: {
    // 登录
    Login({ commit, dispatch }, userInfo) {
      const username = userInfo.username.trim()
      // console.log('userInfo----', userInfo)
      return new Promise((resolve, reject) => {
        login(username, userInfo.password)
          .then((response) => {
            console.log(response)
            const data = response.data
            const tokenStr = data.tokenHead + ' ' + data.token
            setToken(tokenStr)

            // commit('SET_MENUS', data.menus);
            commit('SET_TOKEN', tokenStr)
            commit('SET_NAME', username)

            dispatch('GetInfo')

            resolve()
          })
          .catch((error) => {
            console.log('login--error-------', error)
            reject(error)
          })
      })
    },
    LoginWithToken({ commit, dispatch }, tokenResponse) {
      return new Promise((resolve, reject) => {
        const { tokenHead, token } = tokenResponse.data
        const tokenStr = `${tokenHead} ${token}`
        setToken(tokenStr)

        commit('SET_TOKEN', tokenStr)
        dispatch('GetInfo')
        resolve()
      })
    },

    // 获取用户信息
    GetInfo({ commit /* state */ }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const data = response.data

            // 过期状态设置
            const { expiresTime } = data
            const now = new Date().valueOf()
            const expired = now - expiresTime > 0
            commit('SET_EXPIRED_STATUS', expired)
            /*if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles || [])
          } else {
            reject('getInfo: roles must be a non-null array !')
          }*/
            // console.log('info', data)
            const { storeId, logo, name } = data.store
            set('userId', data.userId)
            set('storeId', storeId)
            set('name', name)
            commit('SET_SAVE', { storeId: storeId })
            commit('SET_NAME', data.username)
            commit('SET_AVATAR', logo || '')
            commit('SET_STORENAME', name)
            commit('SET_WECHAT_NAME', data.weixinNickName)
            commit('SET_WECHAT_OPENID', data.weixinOpenid)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_MENUS', [])
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },
  },
}

export default user
