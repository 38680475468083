import Vue from 'vue'

Vue.prototype.$Confirm = async (text, title, options) => {
    let swalOpt = {
        title,
        text,
        showCancelButton: true,
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText,
    }
    if (options.type) {
        Object.assign(swalOpt, {
            type: options.type
        })
    }
    try {
        await swal(swalOpt)
    } catch (e) {
        throw e
    }
}

const Message = (options) => {
    let { message, type, duration } = options
    new PNotify({
        text: message,
        type: type || 'info',
        delay: duration || 3000
    })
}


Vue.prototype.$Message = Message

export {
    Message
}