const layout = {
  namespaced: true,
  state: () => ({
    count: 0,
    fold: false,
  }),
  mutations: {
    increment(state) {
      // 这里的 `state` 对象是模块的局部状态
      state.count++
    },
    setFold(state, fold) {
      console.log('setFold---', state, 'fold:',fold)
      state.fold = fold
    },
  },

  getters: {
    doubleCount(state) {
      return state.count * 2
    },
    getFold(state) {
      return state.fold
    },
  },
  actions: {
    incrementIfOddOnRootSum({ state, commit, rootState }) {
      if ((state.count + rootState.count) % 2 === 1) {
        commit('increment')
      }
    }
  }
}
export default layout