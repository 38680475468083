import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import chat from './modules/chat'
import user from './modules/user'
import themeType from './modules/themeType'
import layout2 from './modules/layout'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    chat,
    themeType,
    layout: layout2
  },
  getters
})

export default store
